@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600&family=Poppins:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap");

.navbar {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "poppins", sans-serif;
}

header {
  z-index: 2001;
}

/* .Topbar{
    height: 30px;
    background-color: #333;
    color: gray;
    display: flex;
}

.Topbar__right{
    margin-left: 800px;
} */

.NavSocialIcon {
  color: gray;
  margin-right: 12px;
  cursor: pointer;
}

.NavSocialIcon:hover {
  color: rgb(178, 170, 170);
  cursor: pointer;
}

header {
  height: 40px;
}

.headerNAV {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #F1F1FD;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  height: 70px;
}

header .logo {
  font-weight: bolder;
  font-size: 25px;
  text-decoration: none;
}

header .navbar ul li {
  position: relative;
  float: left;
  list-style: none;
}

.NavItem {
  font-size: 17px;
  padding: 17px;
  /* color: chartreuse; */
  display: block;
  text-decoration: none;
}

.NavItem::after {
  content: "";
  display: block;
  height: 2px;
  background-color: #6844f8;
  position: absolute;
  left: 0;
  right: 0;
  transform-origin: left;
  transform: scale(0, 1);
  transition: transform ease-in-out 0.2s;
}

.NavItem:hover::after {
  /* background: chartreuse;
    color: black;
    border-radius: 0px 0px 25px 25px;  */
  transform: scale(1, 1);
}

.LoanItem {
  font-size: 15px;
  padding: 12px;
  /* color: chartreuse; */
  display: block;
  text-decoration: none;
  width: 200px;
  background: #ffff;
  margin-bottom: -10px;
}

.LoanItem:hover {
  text-decoration: underline #6844f8;
}

header .navbar ul li ul {
  position: absolute;
  left: 0;
  display: none;
}

header .navbar ul li:focus-within > ul,
header .navbar ul li:hover > ul {
  display: initial;
}

.menudiv {
  display: none;
}

.Menu {
  /* border: 1px solid white; */
  border-radius: 2px;
}

#menu-bar {
  display: none;
}

.email a {
  text-decoration: none;
  color: gray;
  cursor: pointer;
  margin-right: 25px;
  margin-left: 45px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.email:hover {
  color: rgb(173, 167, 167);
}

.Topbar__left {
  display: flex;
}

/*Responsive*/

@media (max-width: 1000px) {
  .menudiv {
    display: initial;
    padding: 6px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  }

  header .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
    margin: 0;
  }

  header .navbar ul li {
    width: 100%;
  }

  header .navbar ul li ul {
    position: relative;
    width: 100%;
  }

  header .navbar ul li ul li {
    background: #eee;
    width: 100%;
    height: 60px;
  }

  .LoanItem {
    width: 100%;
  }

  .LoanItemUl {
    background-color: #333;
  }
  .LoanItemUl .link {
    text-decoration: none;
  }
  #menu-bar:checked ~ .navbar {
    display: initial;
  }
}
