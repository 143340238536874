html{
    color: white;
}

.form{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.applyFormContainer{
    height: auto;
    background-color: #061b72;
}



/* .applyFormContainer2{
    display: flex;
    align-items: center;
    justify-content: center;
} */

/* .formContainer{
    margin-right: 100px;
} */

#Loan{
    margin-top: 10px;
    background-color: #061b72; 
    border: none;
    color: #5e75d4;
    font-size: x-large;
    margin-right: 80px;
    margin-bottom: 23px;
}

.form input{
    border: none;
    background-color: #061b72;
    font-size: x-large;
    color: #5e75d4;
}

.form input:focus{
    outline: none;
}

#service{
    background-color: #061b72; 
    border: none;
    color: #5e75d4;
    font-size: x-large;
    margin-right: 140px;
    margin-bottom: 23px;
}

#service, #Loan:focus{
    outline: none;
}

.Loan{
    margin-top: 20px;
}


.formDesc input{
    margin-left: -70px;
}

.SubmitBtn{
    border-radius: 10px;
    border-style: none;
    height: 35px;
    width: 300px;
    background-color: #5e75d4;
    font-weight: 500;
}

.ContactusHeading{
    display: flex;
    justify-content: center;
    color: #061b72;
    text-decoration: underline;
    margin-bottom: 10px;
}

.formDesc{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 400px;
    margin-top: 23px;
}

.inputHr{
    color: white; 
    background-color: #5e75d4;
    height: 2px;
    width: 350px;
}

@media screen and (min-width: 1000px){
    .formContainer{
        width: 40%;
    }
}



@media screen and (max-width: 1000px){
    .form{
       height: auto;
        flex-direction: column;
    }
    .formDesc{
        position: relative;
        left: -23px;
        margin-top: 0px;
    }
    
    
}
