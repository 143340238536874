.CreditCard{
    margin-top: 30px;
    background: linear-gradient(180deg, rgba(231, 50, 235, 0.2) 0%, rgba(219, 61, 223, 0.164583) 17.71%, rgba(202, 77, 205, 0.111458) 44.27%, rgba(219, 38, 223, 0.06875) 65.62%, rgba(176, 101, 177, 0.0323998) 83.8%, rgba(90, 52, 90, 0) 100%), #FFFFFF;
}

.CCtop{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 190px;
    width: 1200px;
    margin-left: 160px;
    /* border-radius: 20px;
    box-shadow: 5px 5px 20px -10px black; */
}

.CCtopH1{
    margin-top: 20px;
}

.CCtop2{
    position: absolute;
    top: 25%;
    left: 0;
    display: flex;
}

.CCtopImg{
    width: 800px;
    height: 250px;
    border-radius: 0 120px 120px 0;
}

.SideImgContainer{
    position: absolute;
    left: 100%;
}

.CCSideImg{
    /* position: absolute; */
    width: 300px;
    height: 150px;
    border-radius: 10px;
    margin: 20px;
    transform: scale(1.1, 1.1);
    -webkit-animation-name: zoomin;
    -webkit-animation-duration: 1s;
    animation-name: zoomin;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}


@-webkit-keyframes zoomin {
    from {transform: scale(1, 1);}
    to {transform: scale(1.1, 1.1);}
}

@keyframes zoomin {
    from {transform: scale(1, 1);}
    to {transform: scale(1.1, 1.1);}
}


.Img2{
    position: absolute;
    top: 20%;
}

.CCtopH1{
    color: rgb(11, 11, 143);
    font-weight: 700;
}

.CCtopP{
    color: rgb(24, 24, 75);
    font-weight: 600;
    font-size: larger;
}

.CardContainer{
   margin-top: 340px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 1200px;
   margin-left: 180px;
}

.Card{
    background-color: rgb(233, 248, 233);
    height: 470px;
    width: 350px;
    border-radius: 20px;
    box-shadow: 2px 2px 10px 2px black;
    margin: 20px;
    margin-bottom: 40px;
}

.CardImg{
    height: 180px;
    width: 280px;
    border-radius: 20px;
    margin: 25px 35px;
    /* box-shadow: 2px 2px 5px 2px black; */
}

.Card h5{
    font-weight: 700;
    margin: 15px;
    margin-left: 20px;
}

.Card p{
    font-family: Arial, Helvetica, sans-serif;
    margin: 12px;
}


.CardBtn{
    margin: 20px;
    border: none;
    font-weight: 500;
    font-size: larger;
    width: 130px;
    margin-left: 40px;
    background-color: rgb(255, 208, 0);
    border-radius: 10px;
    height: 40px;
}

.CardBtn:hover{
    background-color: rgb(130, 107, 4);
    color: white;
}

.PrevBtn, .NextBtn{
    height: 55px;
    width: 55px;
    border-radius: 10px;
    box-shadow: 2px 2px 15px black;
    margin: -40px;
    z-index: 1000;
    cursor: pointer;
}

.even{
    background-color: rgb(244, 213, 218);
}

.TypeOfCard{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TypeOfCard h2{
    color: rgb(11, 11, 143);
    margin: 25px;
}

.TocItemContainer{
    display: flex;
    flex-wrap: wrap;
    width: 1000px;
    /* margin-left: 120px; */
}

.TOCItem{
    margin: 10px 60px;
}

.TOCImg{
    width: 100px;
    height: 100px;
}

.TOCDesc{
    margin-left: 25px;
    cursor: pointer;
}

.TOCDesc:hover{
    color: rgb(142, 140, 140);
    text-decoration: underline;
}

.CreditCardBenifit{
    background: linear-gradient(0deg, rgba(231, 50, 235, 0.2) 0%, rgba(219, 61, 223, 0.164583) 5.97%, rgba(202, 77, 205, 0.111458) 11.65%, rgba(219, 38, 223, 0.06875) 17.99%, rgba(176, 101, 177, 0.0323998) 24.15%, rgba(90, 52, 90, 0) 98.82%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
display: flex;
flex-direction: column;
align-items: center;
}

.CreditCardBenifit h2{
    margin-top: 20px;
    color: rgb(11, 11, 143);

}

.CCBenifitContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.CCBenifitDesc{
    width: 350px;
    text-align: center;
}

.CCBenifit{
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CCBImg{
    width: 150px;
    border-radius: 20px;
    margin-bottom: 10px;
}

.QuesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    background-color: white;
    margin-top: 35px;
    margin-left: 170px;
    padding: 40px 90px;
}

.Ques h4{
    text-decoration: none;
}

.QuesContainer2{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1200px;
    margin-top: 35px;
    margin-left: 170px;
    padding: 40px 90px;
    background: linear-gradient(0deg, rgba(231, 50, 235, 0.2) 0%, rgba(219, 61, 223, 0.164583) 22.21%, rgba(202, 77, 205, 0.111458) 42.82%, rgba(90, 52, 90, 0) 60.36%, rgba(176, 101, 177, 0.0323998) 76.91%, rgba(219, 38, 223, 0.06875) 91.36%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
margin-bottom: 20px;
}