.loan-calculator {
    font-family: "Roboto", sans-serif;
    width: 600px;
    margin: 24px auto;
    background: #fff;
    box-shadow: 0 12px 50px -11px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    color: #14213d;
    overflow: hidden;
  }
  
  .loan-calculator,
  .loan-calculator * {
    box-sizing: border-box;
  }
  
  .loan-calculator .top {
    background: #14213d;
    color: #fff;
    padding: 32px;
  }
  
  .loan-calculator .top h2 {
    margin-top: 0;
    font-size: xx-large;
    font-weight: 500;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  
  .loan-calculator form {
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }
  
  .loan-calculator .title {
    margin-bottom: 16px;
  }
  
  .loan-calculator form input {
    font-size: 20px;
    padding: 8px 24px;
    width: 100%;
  }
  
  .loan-calculator .result {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .loan-calculator .result .left {
    width: 100%;
    padding: 8px 32px;
  }
  
  .loan-calculator .left h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  
  .loan-calculator .result .value {
    font-size: 30px;
    font-weight: 900;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(20, 33, 61, 0.2);
  }
  
  .loan-calculator .result .value::before {
    content: "₹";
    font-size: 24px;
    font-weight: 400;
    margin-right: 6px;
    opacity: 0.4;
  }
  
  .loan-calculator .calculate-btn {
    background: #e63946;
    color: #fff;
    border: none;
    padding: 8px 32px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 900;
    cursor: pointer;
    margin: 24px 0;
  }
  
  .loan-calculator .right {
    width: 50%;
  }
  
  @media (max-width: 650px) {
    .loan-calculator {
      width: 90%;
      max-width: 500px;
    }
  
    .loan-calculator form {
      flex-direction: column;
      gap: 20px;
    }
  
    .loan-calculator .result {
      flex-direction: column;
      text-align: center;
    }
  }