
html{
    color: white;
}
.FooterContainer{
    background-color: rgb(37, 34, 34);
    color: #ffec9d;
    display: flex;
    justify-content: space-around;
    padding-top: 40px;
}

.FooterContainer p{
    margin-left: -50px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.Footer{
    background-color: rgb(37, 34, 34);
    height: 320px;
}

h4{
    text-decoration: underline;
}

.name h4{
    text-decoration: none;
}

.name{
    width: 250px;
}

.name p{
    /* margin-top: 30px; */
    color: rgb(165, 164, 164);
}

.socialIcon{
    color: white;
    margin-right: 15px;
    cursor: pointer;
}

.services h4{
    /* margin-top: -15px;  */
     margin-bottom: 5px; 
}

.serviceItem{
    color: rgb(165, 164, 164);
    margin-bottom: 5px;
    cursor: pointer;
}

.serviceItem:hover{
    text-decoration: underline;
}

.CustomerSupport h4{
    /* margin-top: -15px; */
    margin-bottom: 5px;
}

.CSItems{
    color: rgb(165, 164, 164);
    margin-bottom: 5px;
    cursor: pointer;
}

.CSItems:hover{
    text-decoration: underline;
}

.Address{
    width: 250px;
}

.contactInfo h4{
    /* margin-top: 40px; */
    margin-bottom: 7px;
}

.CIItem{
    color: rgb(165, 164, 164);
    margin-bottom: 5px;
    cursor: pointer;
}

.email a{
    color: rgb(165, 164, 164);
    margin-left: 0;
}

.email a:hover{
    text-decoration: underline;
}

.CIItem div{
    margin-bottom: 10px;
}

.CIItem span:hover{
    text-decoration: underline;
}

.ContactIcon{
    color: white;
    margin-right: 12px;
    cursor: pointer;
}

.copywrite{
    color: rgb(240, 229, 229);
    text-align: center;
}

@media screen and (max-width: 800px){
    .FooterContainer{
        align-items: center;
        flex-direction: column;
    }
    .FooterContainer p{
        margin-left: -20px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .services{
        width: 250px;
    }
    .CustomerSupport{
        width: 250px;
    }
    .CIItem{
        width: 250px;
    }
    .padding{
        padding-top: 30px;
    }
    .copywrite{
        color: black;
    }
    
}
@media screen and (max-width: 450px){
    
      /* .FooterContainer{
        
      } */
      .applyFormContainer input{
        font-size: 100%;
        
      }
      .applyFormContainer{
        padding-left: 70px;
      }
      .applyFormContainer h2{
        padding-right: 70px;
      }
      .inputHr{
        width: 310px;
      }
      .formDesc{
        padding-right: 20px;
      }
      
    
}