.aboutUs{
    height: auto;
    margin-top: 30px;
    background-color: rgb(226, 234, 241);
    /* margin-bottom: 20px; */
}



.aboutImgContainer {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  background-color: black;
  background-color: black;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  
}

.aboutImg {
  width: 100%;
  height: auto;
  opacity: 0.7;
  
  object-fit: contain;
}


.aboutImgContainer h1{
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
  padding: 20px;
  font-weight: 300;
  color: white;
}

.aboutImgContainer h1 hr{
    margin-top: -3px;
    color: rgb(244, 209, 13);
    height: 3px; /* Adjust the value as desired */
  border: none;
  background-color: rgb(245, 209, 7);
  opacity: 2;
}

.AUH4, .AUH2{
  font-size: 1.8rem;
  margin: 15px 15px 15px 0px;
  text-decoration: none;
  font-weight: 400;
  color: #061b72;
}


.discriptionContainer{
  margin: 30px;
  width: 50%;
}

.discriptionContainer1{
  display: flex;
}

/* .discriptionContainer2{
  width: 50%;
} */

/* .discriptionContainer2 h4, .discriptionContainer2 h2{
  margin-bottom: 20px;
} */
/* 
.aboutImg2{
   margin: 70px; 
  margin-left: 130px;
  margin-top: 130px;
  opacity: 0.8;
  background-color: black;
  border-radius: 50%;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
}  */

.discriptionContainer2{
  margin: 40px 40px 0px 70px;
  width: 700px;
}

.discriptionContainer2 p{
  margin-bottom: 25px;
  
}

.discriptionContainer2 h4{
  margin-top: 50px;
}

.VisionImg{
  height: 350px;
  width: 400px;
  margin-left: 50px;
  margin-right: 50px;
}

.visionDesc{
  display: flex;
}

/* .visionDesc p{
  margin-top: 80px;
  margin-left: 100px;
  width: 400px;
} */

.ourvision{
  margin: 20px 50px 10px 50px;
  color: #061b72;
}
/* 
.WhatWeDoContainer{
  margin-bottom: 50px !important;
} */

/* .WhatWeDo{
  width: 1200px;
  margin-left: 100px;
} */

.WhatWeDoContainer h1{
  /* margin-left: 1000px; */
  color: #061b72;
}

.WhatWeDo p{
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px){
  .discriptionContainer1{
      display: flex;
      justify-content: space-around;
      align-items: center ;

      flex-direction: column;
  }
  .discriptionContainer2{
    padding: 10px;
    height: auto;
}

.WhatWeDoContainer h1{
  text-align: center;
}

.WhatWeDo{
  margin-top: 30px;
}
}

@media screen and (max-width: 850px){
    .visionDesc{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .visionDesc img{
      margin-bottom: 30px;
    }
    .visionDesc p{
      margin-inline: 0px;
      padding-inline: 0px;
    }
    .ourvision{
      margin-bottom: 50px;
    }

    .discriptionContainer2 h4{
      margin-top: 20px;
      margin-bottom: 25px;
    }
    .discriptionContainer2 p{
      margin-inline: 0px;
      padding-inline: 0px;
      
    }
}

@media screen and (max-width: 850px){
  .aboutImgContainer h1{
    margin-inline-start: 30px;
  }
  .aboutImgContainer{
    height: 80%;
  }
}

@media  screen and (min-width:670px){
  .aboutImgContainer{
      height: 400px;
      
  }
}