.contactUs {
  width: 100%;
  max-width: 1310px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  position: relative;
  float: none;
}
.contact-form-box {
  max-width: 800px;
  margin: 0 auto;
}
.field-row:not(:last-child) {
  margin-bottom: 24px;
}

.field-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.gestart-footer-sec {
  display: flex;
  background-color: #0a0033;
  margin: 50px 30px;
  justify-content: center;
  color: white;
  border-radius: 20px;
  padding: 80px;
}

.green-lb {
  background: #c3f4e5;
  color: #09ab79;
}
.label-lb {
  border-radius: 50px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}
@media screen and (max-width: 1200px) {
  .label-lb {
    padding: 8px 15px;
    font-size: 14px;
  }
}
section.contact-form-sec {
  position: relative;
  padding-top: 50px;
}
.contact-form-sec .main-heading {
  padding-bottom: 22px;
}

.form-bottom-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.form-bottom-box p:not(:last-child) {
  padding-right: 45px;
  max-width: calc(100% - 182px);
}

.form-bottom-box p {
  width: auto;
  margin-bottom: 0;
}
.form-bottom-box .btn {
  min-width: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease 0s;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: calc(100% - 46px);
  color: white;
}
@media screen and (max-width: 980px) {
  body p {
    font-size: 16px;
  }
}
form input,
form textarea,
select {
  margin-bottom: 10px;
  margin-top: 20px;
  border: 2px solid rgb(165, 165, 165);
  padding: 10px;
  border-radius: 6px;
  background-color: transparent;
}

.required {
  color: red;
}
form input:focus,
form textarea:focus,
form .Button:focus,
select:focus {
  outline: none;
}

#textarea {
  height: 100px;
}

#services {
  margin-bottom: -20px;
}

select {
  margin-top: 15px;
}

@media screen and (max-width: 900px) {
  .contactUs {
    height: 35%;
  }
}
