.HomeLoan{
    height: auto;
    margin-top: 30px;
}




.HomeLoanImgContainer {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  background-color: black;
  background-color: black;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  
}

.HLImg {
  width: 100%;
  height: auto;
  opacity: 0.7;
  
  object-fit: fill;
}




.MorL{
  margin-top: -250px;
}

.PerL{
  margin-top: -70px;
}

.CarL{
  margin-top: -470px;
}

.GoL{
  margin-top: -650px;
}

.VehL{
  margin-top: -150px;
}

.MacL{
  margin-top: -170px;
}

.HomeLoanImgContainer h1{
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
  padding: 20px;
  font-weight: 300;
  color: white;
}

.HomeLoanImgContainer h1 hr{
    margin-top: -3px;
    color: rgb(244, 209, 13);
    height: 3px; /* Adjust the value as desired */
  border: none;
  background-color: rgb(245, 209, 7);
  opacity: 2;
}

/* .HomeLoanContainer2 p {
  width: 90%;
  margin-left: 30px;
} */

.HLH1 {
  text-decoration: none;
 
}

.HLH1 hr {
  color: rgb(244, 209, 13);
  height: 3px;
  /* Adjust the value as desired */
  border: none;
  background-color: rgb(244, 209, 13);
  opacity: 2;
  width: 130px;
}

 .HomeLoanContainer1 {
  display: flex;
  justify-content: space-around;
  margin-inline: 20px;
} 

 .HomeLoanContainer2 {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} 


.HLSideImg {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  cursor: pointer;
  height: 450px;
  margin: 30px;
  border-radius: 10px; 
}

.HLImg2{
  border-radius: 10px;
  width: auto;
  
}

.ApplyBtn{
  width: 170px;
  height: 40px;
  font-weight: 600;
  font-size: larger;
  /* margin-bottom: 18px; */
  background: rgb(74, 74, 74);
  color: white;
  border: none;
  /* margin-left: 130px; */
  margin: 30px 0px 18px 130px;
}

.ApplyBtn:hover{
  background-color: rgb(97, 95, 95);
}

@media screen and (max-width: 1000px){
  
  .HomeLoanContainer1{
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    
  }
  .HLSideImg{
    height: 80%;
  }
  .HomeLoanImgContainer{
    display: none;
  }
}


@media screen and (max-width: 550px){
  
  .HLSideImg{
    height: 40%;
  }
  .HomeLoanContainer2{
    width: 330px;
    margin-inline: 0px;
    padding-inline: 0px;
  }
 
  
}

@media  screen and (min-width:670px){
  .HomeLoanImgContainer{
      height: 350px;
  }
}