@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600&family=Poppins:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap');

.insurance {
  height: auto;
  margin-top: 30px;
}

p{
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
}

hr{
  margin-bottom: 15px;
}




.insuranceImgContainer {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  background-color: black;
 
  background-color: black;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
}

.insuranceImg {
  width: 100%;
  height: auto;
  opacity: 0.7;
  
  object-fit: contain;
}

.insuranceImgContainer h1 {
  position: absolute;
  top: 50%;
  left: 13%;
  transform: translate(-50%, -50%);
  padding: 20px;
  font-weight: 300;
  color: white;
  
}

.insuranceImgContainer h1 hr {
  margin-top: -3px;
  color: rgb(244, 209, 13);
  height: 3px;
  /* Adjust the value as desired */
  border: none;
  background-color: rgb(245, 209, 7);
  opacity: 2;
}

.InsuranceContainer2 p {
  width: 95%;
}

.INSH4,
.HowDoesIteWork h1,
.BenifitsOfInsurance,
.financialRiskContainer h3,
.whatIsPremium h3 {
  text-decoration: none;
  font-weight:500;
  color: #1f2360;
  font-size: x-large;
  margin: 8px;
  font-family: 'poppins', sans-serif;
}

.INSH4 hr {
  color: rgb(244, 209, 13);
  height: 3px;
  /* Adjust the value as desired */
  border: none;
  background-color: rgb(245, 209, 7);
  opacity: 2;
}

.InsuranceContainer1 {
  display: flex;
}

.InsuranceContainer2 {
  width: 45%;
  margin-top: 40px;
  margin-right: 100px;
  margin-left: 100px;
}



.InsuranceContainer2 h4,
.InsuranceContainer2 h2 {
  margin-bottom: 20px;
}

.insuranceCard1,
.insuranceCard2 {
  width: 450px;
  height: 120px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  border-radius: 20px;
  margin: 20px;
  cursor: pointer;
  font-family: 'Agdasima', sans-serif;
}

.insuranceCard1 h5,
.insuranceCard2 h5 {
  font-weight: 400;
  margin: 40px 50px;
  /* color:  rgb(244, 209, 13); */
}

.insuranceCard1 hr,
.insuranceCard2 hr {
  color: rgb(247, 7, 7);
  height: 3px;
  /* Adjust the value as desired */
  border: none;
  background-color: rgb(247, 7, 7);
  opacity: 2;
}

.GILogoImg,
.LILogoImg {
  border-radius: 50%;
  height: 80px;
  margin: auto;
}

.insuranceCard1 {
  background-color: rgb(245, 235, 235);
}

.insuranceCard1:hover {
  background-color: rgb(245, 224, 224);
}

.insuranceCard2 {
  background-color: rgb(227, 241, 227);
}

.insuranceCard2:hover {
  background-color: rgb(216, 232, 216);
}

.insuranceContainer2 {
  margin: 40px 40px 0px 70px;
  width: 700px;
}

.HowDoesIteWork,
.BenifitsContainer {
  /* width: 1100px; */
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HowDoesIteWork hr {
  color: rgb(244, 209, 13);
  height: 3px;
  /* Adjust the value as desired */
  border: none;
  background-color: rgb(245, 209, 7);
  opacity: 2;
}

.HowDoesIteWorkPara,
.BenifitsContainer p,
.financialRiskContainer p,
.whatIsPremium p{
  width: auto;
  /* margin-left: 60px; */
  height: auto;
}

.HowDoesIteWork{
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
ul{
  text-align: left;
}
.insuranceCardContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
h1{
  font-size: 2rem;
}


@media screen and (max-width:1100px) {
  .InsuranceContainer1{
    flex-direction: column;
  }
  .InsuranceContainer2{
    width: auto;
    margin: 50px;
  }
  .insuranceCard1,.insuranceCard2{
    width: 400px;
  }
  .insuranceCardContainer {
    margin-top: 0;
  }
  
}

@media screen and (max-width:500px) {
  
  .InsuranceContainer2{
    width: auto;
    margin: 20px;
  }
  .insuranceCard1,.insuranceCard2{
    width: 340px;
  }
  .HowDoesIteWork{
    padding: 10px;
  }
  h1{
    font-size: 1.5rem;
    margin-left: 40px;
  }
}
@media  screen and (min-width:800px){
  .insuranceImgContainer{
      height: 350px;
      
  }
}