/* .Investment{
    height: auto;
    margin-top: 30px;
} */



.InvestmentImgContainer {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    background-color: black;
    background-color: black;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
    
}

.InvestmentImg {
    width: 100%;
    height: auto;
    opacity: 0.7;
    
    object-fit: contain;
}


.InvestmentImgContainer h1 {
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    padding: 20px;
    font-weight: 300;
    color: white;
}

.InvestmentImgContainer h1 hr {
    margin-top: -3px;
    color: rgb(244, 209, 13);
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color: rgb(245, 209, 7);
    opacity: 2;
}

.InvestmentContainer1{
    margin-top: 50px;
}

.INVST{
    
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.INVST hr {
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color:  #3b82f6;
    opacity: 2;
}

.INVST p {
    
    
    font-weight: 400;
}

.INVSTH4 {
    text-decoration: none;
    font-weight: 400;
    font-size: larger;
    margin: 20px;
   width: auto;
} 

.INVST h5{
    margin-left: 50px;
    color: #3b82f6;
    font-weight: bolder;
}


.cardContainer{
    display: grid;
    grid-template-columns: repeat(4, auto);
    place-items: center;
} 

.cardC1, .cardC2, .cardC3, .cardC4 {
    width: 240px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
    margin: 10px;
    display: flex;
    cursor: pointer;
}

 .cardC1 h5, .cardC2 h5, .cardC3 h5, .cardC4 h5{
    width: 100px;
    margin-top: 8px;
    margin-right: 20px;
    padding-top: 5px;

} 

.cardC2 h5{
    color: rgb(17, 192, 17);
}

 .INVSTcardImg{
    border-radius: 50%;
    height: 70px;
    margin-top: 18px;
} 

.cardC1{
    background-color: rgb(245, 223, 223);
}

.cardC2{
 background-color: rgb(234, 248, 234);
}

.cardC3 h5{
    color: rgb(141, 141, 14);
}

.cardC3{
    background-color: rgb(243, 243, 231);
}

.cardC4 h5{
    color: blue;
}

.cardC4{
    background-color: rgb(220, 220, 229);
}
p {
    padding-inline: 50px;
}
h5{
    margin: 8px;
}
.INVST{
    margin-bottom:25px ;
}

@media screen and (max-width: 1200px){
    .cardContainer{
        display: grid;
        grid-template-columns: repeat(3,auto);
        justify-content: space-around;
    }
    p{
        padding-inline: 20px;
    }
    
}

@media screen and (max-width: 800px){
    
    .cardContainer{
        display: grid;
        grid-template-columns: repeat(2,auto);
        justify-content: space-around;
    }
    p {
        padding-inline: 20px;
    }
    
}
@media screen and (max-width: 550px){
    
    .cardContainer{
        display: grid;
        grid-template-columns: repeat(1,auto);
        justify-content: space-around;
    } 
    p {
        padding-inline: px;
    }
    h5{
        margin-left: -10px;
    }
    .InvestmentImgContainer h1{
        margin-left: 70px;
    }
}
@media  screen and (min-width:670px){
    .InvestmentImgContainer{
        height: 350px;
        
    }
}