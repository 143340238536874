.GeneralInsurance{
    height: auto;
    margin-top: 30px;
}



.GeneralInsuranceImgContainer {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    background-color: black;
    background-color: black;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
    
}

.GeneralInsuranceImg {
    width: 100%;
    height: auto;
    opacity: 0.7;
    
    object-fit: contain;
}



.GeneralInsuranceImgContainer h1 {
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    padding: 20px;
    font-weight: 300;
    color: white;
}

.GeneralInsuranceImgContainer h1 hr {
    margin-top: -3px;
    color: rgb(244, 209, 13);
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color: rgb(245, 209, 7);
    opacity: 2;
}



.LIImg2{
    border-radius: 50%;
}

.GIC{
   
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.GIC hr {
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color:  #3b82f6;
    opacity: 2;
}



.GIH4 {
    text-decoration: none;
    font-weight: 400;
    margin-top: 20px;
}

.GIH4 hr {
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color: #3b82f6;
    opacity: 2;
}

.GIC h5{
    margin-left: 50px;
    color: #3b82f6;
    font-weight: bold
}

tr, th{
    border: 1px solid black;
    height: 60px;
}

.Left-Heading{
padding-left: 70px;
width: 800px;
}

.Right-Heading{
padding-left: 40px;
}

.Left-Cell{
    padding-left: 30px;
    padding-right: 80px;
}

.Right-Cell{
    padding-left: 40px;
}

table{
   margin: 28px;
}

@media  screen and (max-width:940px) {
    
    .GIC{
        padding: 0px 25px 0px 25px;
    }
    .GITypes{
        margin: 0px;
        padding: 0px;
    }
}
@media  screen and (min-width:670px){
    .GeneralInsuranceImgContainer{
        height: 350px;
        
    }
}