@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600&family=Poppins:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap");

.LoanImg {
  width: 100%;
  /* height: 100%; */
  padding-bottom: 90px;
}
body{
  background-color: #F1F1FD;
}
html,body{
  width: 100%;
  height: 100%;
  margin-left: 0px;
  padding: 0px;
  overflow-x: clip;
}

.HomeLoanimg {
  margin-top: -100px;
  /* height: 150%; */
}

.PLoanimg {
  margin-top: 100px;
  /* height: 150%; */
}

.CLoanimg {
  margin-top: 140px;
  /* height: 150%; */
}

.slider {
  position: relative;
  width: 100%;
  margin-top: 0;
  overflow: hidden;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 1;
  }
}

.txt {
  font-family: sans-serif;
  box-sizing: border-box;
  position: relative;
  color: black;
  letter-spacing: 2px;
  line-height: 35px;
  top: 40%;
  left: 15%;
  -webkit-animation-name: posi;
  -webkit-animation-duration: 2s;
  animation-name: posi;
  animation-duration: 2s;
  z-index: 1;
}

.HCImgContainer {
  height: 400px;
  overflow: hidden;
  position: relative;
  /* background-color: black; */
}

.HCCImg {
  width: 90%;
  height: 380px;
  border-radius: 0 120px 120px 0;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
}

.HCImgContainer h1 {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  padding: 20px;
  /* font-weight: 300; */
  /* color: white; */
}

.HCImgContainer p {
  position: absolute;
  top: 60%;
  left: 16%;
  transform: translate(-50%, -50%);
  padding: 20px;
  /* font-weight: 300; */
  /* color: white; */
}

.HCImgContainer h1 hr {
  margin-top: -3px;
  /* color: rgb(244, 209, 13); */
  height: 3px;
  border: none;
  /* background-color: rgb(245, 209, 7); */
  opacity: 2;
}

.HInImg {
  height: 300px;
  width: 550px;
  object-fit: cover;
  border-radius: 10px;
  /* margin-left: 30px;
  margin-right: 50px; */
  box-shadow: 5px 5px 5px rgba(209, 196, 196, 0.1);
}

.HInIDesc {
  display: flex;
}


.HInI {
  margin: 20px 50px 20px 50px;
}

.WCContainer {
  display: flex;
}

.WCFirstBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  background-color: #e7e7fc;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
  margin: 20px;
  height: 330px;
  border-radius: 10px;
  padding: 15px 35px;
}

.WCFirstBlock:hover {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
  background-color: #cacaf8;
}

.WCFirstBlock h3 {
  /* text-decoration: none; */
  font-weight: 700;
  margin: 8px;
  font-size: large;
  color: #5e75d4;
}

.Welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0px;

  /* background-color: rgb(37, 34, 34); */
}

.WCHeading {
  
  /* color: white; */

  font-family: "Agdasima", sans-serif;
}

.WCtxt {
  width: 250px;
}

.Wplogo {
  position: fixed;
  height: 80px;
  top: 85%;
  right: 5%;
  z-index: 999;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgb(121, 217, 121);
  transform: scale(1);
  animation: pulse 2s infinite;
}
@media (min-width: 240px) {
  .hero-container {
    text-align: center;
    align-items: center;
  }
  .hero-container h1 {
    font-size: 3rem /* 48px */;
    line-height: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(121, 217, 121);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 8px 10px rgb(121, 217, 121);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 10px 12px rgb(121, 217, 121);
  }
}

@-webkit-keyframes posi {
  from {
    left: 25%;
  }

  to {
    left: 15%;
  }
}

@keyframes posi {
  from {
    left: 25%;
  }

  to {
    left: 15%;
  }
}

.txt h1 {
  color: chartreuse;
  font-weight: bold;
  margin: 20px;
}

.txt p {
  font-weight: bold;
  font-size: 20px;
}

@-webkit-keyframes zoomin {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.1, 1.1);
  }
}

@keyframes zoomin {
  from {
    transform: scale(1, 1);
  }

  to {
    transform: scale(1.1, 1.1);
  }
}

.applyForLoan {
  /* background-color: rgb(37, 34, 34); */
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  /* color: #fff; */
}

.applyHeading {
  margin: 7px auto;
  margin-top: 10px;
  color: #5e75d4;
  cursor: pointer;
  font-family: "poppins", sans-serif;
  letter-spacing: 0.8px;
  font-size: xx-large;
}

.applyHeading:hover {
  color: #061b72;
}

.applyItemContainer {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(8, auto);
}

.applyItem {
  background-color: #fff;
  height: 150px;
  width: 150px;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #061b72;
  box-shadow: 10px 10px 10px rgba(90, 86, 86, 0.1);
}

.applyImg {
  height: 70px;
  width: 70px;
  /* border-radius: 50%; */
  mix-blend-mode: multiply;
}

.applyTxt {
  font-size: 0.9em;
  margin-top: -12px;
  font-weight: 600;
}

.applyItem:hover {
  cursor: pointer;
  color: #061b72;
  /* background: linear-gradient(145deg, #cacaca, #f0f0f0); */
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

.applycontainer {
  font-weight: 500;
  font-size: large;
  font-family: "poppins" sans-serif;
}

.PartnerBanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  /* background-color: rgb(37, 34, 34); */
}

.PartnerImgContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px;
  justify-content: center;
}

.PartnersHeading {
  font-family: "Agdasima", sans-serif;
  margin-bottom: 20px;
}

.PartnerImg {
  background-color: #ffffff;
  margin: 5px;
  border-radius: 20px;
  width: 300px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.PartnerImg img {
  height: 50px;
  width: 130px;
}

.ShowMore {
  border-radius: 10px;
  border-style: none;
  height: 35px;
  width: 120px;
  background-color: #2E2FDF;
  font-weight: 500;
  margin-bottom: 20px;
  color: #ffffff;
}

.WCImg {
  width: 90px;
  height: 90px;
}



@media screen and (max-width: 800px) {
  .txt {
    line-height: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation-name: posi2;
    -webkit-animation-duration: 2s;
    animation-name: posi2;
    animation-duration: 2s;
  }

  @-webkit-keyframes posi2 {
    from {
      top: 35%;
    }

    to {
      top: 50%;
    }
  }

  @keyframes posi2 {
    from {
      top: 35%;
    }

    to {
      top: 50%;
    }
  }

  .txt h1 {
    font-size: 40px;
  }

  .txt p {
    font-size: 13px;
  }

  .mainDiv {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .txt h1 {
    font-size: 30px;
  }

  .applyItemContainer {
    flex-wrap: wrap;
  }

  .applyForLoan {
    height: 250px;
  }

  .txt {
    width: 80vw;
  }

  .Wplogo {
    position: fixed;
    height: 70px;
    top: 90%;
    right: 7%;
  }
}

@media screen and (max-width: 640px) {
  .LoanImg {
    height: 100%;
    object-fit: cover;
  }

  .applyForLoan h2 {
    padding: 15px 0px 10px 0px;
    font-weight: bold;
    font-size: x-large;
  }

  .applyItemContainer {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }

  .image-container img {
    object-fit: fill;
    height: 300px;
  }

  .image-container {
    width: 350px;
  }


  .WCContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .WCFirstBlock {
    width: 85%;
  }
}

@media screen and (max-width: 1280px) {
  .HInIDesc {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 763px) {
  .mainheading{
    margin: 0px;
    
  }
  
  
  .hero-container h1{
    font-size: 270%;
  }
  
  section{
    flex-direction: column-reverse;
  }
  .discoverdiv{
    display: block;
    margin-inline: auto;
    
  }
  .applyItemContainer{
    margin: 0px;
    padding: 0px 0px 20px 0px;
  }
  .applycontainer{
    margin: 20px 28px 10px 28px;
  }
  .investheading{
    margin: 70px 0px 0px 0px ;

  }
  .insurancediv p{
    margin: 0px;
    padding: 0px;
    width: auto;
  }
  .insurancediv{
    margin: 0px 0px 0px 5px;
    width: 350px;
    
  }
  .formContainer{
    margin-left: -60px;
  }

}

/* .prev, .next{
    position: absolute;
    top: 15%;
    transform: translate(0, -50%);
    font-size: 50px;
    padding: 15px;
    cursor: pointer;
    color: #c04343;
    transition: 0.15;
    user-select: none;
}

.prev:hover, .next:hover{
    color: #3498;
}

.next{
    right: 0;
} */

/* .dotsbox{
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 20px;
    cursor: pointer;
}

.dot{
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 3px solid #ffff;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;
}

.active, .dot:hover{
    border-color: #3498db;
} */

/* .fade{
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade{
    from {opacity: 0.8;}
    to {opacity: 1;}
}

@keyframes fade{
    from{opacity: 0.8;}
    to {opacity: 1;}
} */
