.LifeInsurance {
    height: auto;
    margin-top: 30px;
}

.LifeInsuranceImgContainer {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    background-color: black;
    background-color: black;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
    
}

.LifeInsuranceImg {
    width: 100%;
    height: auto;
    opacity: 0.7;
    
    object-fit: contain;
}

.LifeInsuranceImgContainer h1 {
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    padding: 20px;
    font-weight: 300;
    color: white;
}

.LifeInsuranceImgContainer h1 hr {
    margin-top: -3px;
    color: rgb(244, 209, 13);
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color: rgb(245, 209, 7);
    opacity: 2;
}

.LifeInsuranceContainer2 p {
    width: 90%;
    margin-left: 30px;
}

.LIH4 {
    text-decoration: none;
    font-weight: 400;
}

.LIH4 hr {
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color: #3b82f6;
    opacity: 2;
}

.LifeInsuranceContainer1 {
    display: flex;
    margin-inline: 28px;
}

.LIImg{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.LIImg img{
   width: 80%;
}



.LIC{
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.LIC hr {
    color:  rgb(247, 7, 7);
    height: 3px;
    /* Adjust the value as desired */
    border: none;
    background-color:  #3b82f6;
    opacity: 2;
}





b{
    margin-bottom: 10px;
}

.DockCardContainer{
    display: flex;
    flex-wrap: wrap;
    margin: 15px 40px;
}

.DockCard{
    margin: 10px 10px;
    border-radius: 10px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.3);
    height: 50px;
    padding: 10px;
    background-color: rgb(245, 235, 235);
    font-weight: 450;
}

@media  screen and (max-width:940px) {
    .LifeInsuranceContainer1{
        display: flex;
        flex-direction: column;
        
    }
    .LIImg{
        width: 400px;
    }
    .LifeInsuranceContainer2 p{
        padding: 0px;
        margin: 0px;
        width: auto;
    }
    p{
        width: auto;
    }
    .BenifitsContainer{
        padding: 0px 25px 0px 25px;
    }
    .BenifitsContainer p{
        padding: -10px;
    }
    .LICPara p{
        padding: 0px;
    }
}

@media  screen and (min-width:670px){
    .LifeInsuranceImgContainer{
        height: 350px;
        
    }
}